@import "./asset/scss/themes-vars.module";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}

@font-face {
  font-family: "InterVariableFont";
  src: local("InterVariableFont"),
    url("./asset/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf")
      format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.customInput_padding > .MuiInputBase-root {
  padding: 5px 10px;
}

.customInput_padding > .MuiInputBase-root::before {
  border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInputBase-root::after {
  border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::before {
  border-bottom: 2px solid $errorMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::after {
  border-bottom: 2px solid $errorMain !important;
}

.swal2-container {
  z-index: 99999;
}

.swal2-confirm,
.swal2-cancel {
  outline: none !important;
  box-shadow: none !important;
}

.profile-step {
  .MuiStepLabel-root .Mui-completed {
    color: green !important;
  }

  .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
    color: grey.500 !important;
  }

  .MuiStepLabel-root .Mui-active {
    color: #547dbf;
  }

  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    color: white !important;
  }

  .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
    fill: white !important;
  }

  .MuiStepLabel-labelContainer > span {
    font-size: 1.2rem !important;
    font-weight: 550 !important;
    padding: 2rem 0 !important;
  }

  .MuiStepLabel-iconContainer .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }
}

/* legacy search button design */
.legacy-search {
  width: 12%;
  display: flex;
}

.legacy-search-div {
  cursor: pointer;
  width: 100%;
  height: 3.1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  margin-top: 2.6rem;
  border: 1px solid #ccc;
  background-color: #dcdbdf;
}

/* Styles for screens with a width between 481 and 960 pixels */
@media screen and (min-width: 360px) and (max-width: 960px) {
  .profile-step {
    .MuiStepLabel-label {
      display: flex !important;
    }

    .MuiStepLabel-root .Mui-completed {
      color: green !important;
    }

    .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
      color: grey.500 !important;
    }

    .MuiStepLabel-root .Mui-active {
      color: #547dbf;
    }

    .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
      color: white !important;
    }

    .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
      fill: white !important;
    }

    .MuiStepLabel-labelContainer > span {
      font-size: 0.75rem !important;
      font-weight: 500 !important;
      padding: 0.2rem 0 !important;
      display: flex !important;
      justify-content: center !important;
    }

    .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
      color: #547dbf !important;
    }

    .MuiStepLabel-iconContainer .MuiSvgIcon-root {
      font-size: 1.5rem !important;
    }
  }
  .css-15pdb31-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
  }
}

// Delete Alert


.custom-popup {
  background-color: white; /* Customize as needed */
  border: none; /* Customize as needed */
  /* Add other custom styles here */
}

.custom-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.custom-icon {
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  margin-right: 10px; /* Adjust spacing between icon and title */
}

.custom-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-confirm-button {
  background-color: $buttonOrange !important; /* Change to desired color */
  color: white !important; /* Text color */
  font-size: 16px !important; /* Adjust font size */
  padding: 10px 30px !important; /* Adjust padding for size */
}

.custom-cancel-button {
  background-color: grey !important; /* Change to desired color */
  color: white !important; /* Text color */
  font-size: 16px !important; /* Adjust font size */
  padding: 10px 30px !important; /* Adjust padding for size */
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dedcdc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb {
  /* background: red; */
  border-radius: 10px;
}