.file-drag-and-drop {
  // padding:3rem 10rem !important;
  padding: 8% 6%;
  // width: 50%;
  border: 2px solid #d8dde5 !important;
  border-style: dashed !important;
  background: #f0f8ff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
