.datepicker-input-slot {
  .MuiInputBase-root {
    .Mui-disabled {
      color: #121926 !important;
      -webkit-text-fill-color: #121926 !important;
    }
  }
}

.MuiPickersYear-yearButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected:hover, .MuiPickersYear-yearButton.Mui-selected:focus {
  color: white;
  background-color: #0fadd5;
}

.MuiPickersYear-yearButton:hover {
  color: #121926;
  background-color: none;
}

.MuiPickersYear-yearButton:focus {
  color: #121926;
  background-color: none;
}

.datepicker-input-slot1:disabled {
  color: gray; /* Change to the desired text color */
  font-weight: normal; /* Change to the desired font weight */
}

.ql-toolbar {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ql-container {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.ql-editor {
  min-height: 144px !important;
  max-height: 144px !important;
  max-width: 100% !important;
  overflow-y: auto;
}